
span{ 
  padding-left: 20px;
}

.collapseText:not(.collapseText:last-child) {
  transition:  0.2s !important;
  background-color: rgb(209, 209, 209) !important;
}

.collapseText span {
  padding-right: 20px;
}

.collapseText:last-child span {
  padding-left: 40px;
}

.collapseText:last-child {
  float: left;
 
  width:100% !important;
  display:flex;
  justify-content:center;
  align-items:center;
  background-color: rgb(233, 233, 233) !important;
}


